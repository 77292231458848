import React, { useEffect } from "react";
import { useSpring } from "@react-spring/core";
import { animated } from "@react-spring/web";
import { useTheme } from "@material-ui/core";

export default function SectionSwoosh({ enteredViewport, exitedViewport }) {
  const theme = useTheme();
  const [swooshSpring, setSwooshSpring] = useSpring(() => ({
    from: { x: 0, y: 0, z: 0 },
    config: { delay: 0, duration: 300 },
  }));

  const { xy } = useSpring({
    from: { xy: [0, 0] },
    config: { delay: 0 },
    loop: true,
  });

  useEffect(() => {
    setSwooshSpring(
      enteredViewport
        ? {
            to: [
              { z: 0, y: 0, x: 35 },
              { z: 0, y: 25, x: 35 },
              { z: 20, y: 25, x: 35 },
            ],
          }
        : { x: 0, y: 0, z: 0 }
    );
  }, [enteredViewport]);

  return (
    <div
      style={{
        height: 10,
        position: "relative",
        // marginBottom: "-40vh",
        // background: 'green',
        width: "100%",
        // borderTop: '1px solid red',
        overflow: "hidden",
      }}
    >
      <animated.div
        // src="colorSwoosh1.svg"
        style={{
          width: "100%",
          height: "3px",
          borderTop: `1px dashed ${theme.palette.primary.light}`,
          position: "absolute",
          marginLeft: swooshSpring.x.to((x) => `-${100 - x}%`),
        }}
      />

      <animated.div
        // src="colorSwoosh2.svg"
        style={{
          width: "100%",
          height: "3px",
          borderTop: `1px dashed ${theme.palette.primary.dark}`,
          position: "absolute",
          marginLeft: swooshSpring.y.to((y) => `-${100 - y}%`),
          top: 3,
        }}
      />

      <animated.div
        // src="colorSwoosh3.svg"
        style={{
          width: "100%",
          height: "3px",
          borderTop: `1px dashed ${theme.palette.primary.main}`,
          position: "absolute",
          marginLeft: swooshSpring.z.to((z) => `-${100 - z}%`),
          top: 6,
        }}
      />
    </div>
  );
}

