import * as React from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Link from "@material-ui/core/Link";

function Copyright() {
  return (
    <Typography variant="body2" color="primary">
      {"Copyright © "}
      <Link color="inherit" href="https://material-ui.com/">
        conor-breslin.co.uk
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

export default function Footer() {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        paddingTop: "30vh",
      }}
    >
      {/* <CssBaseline /> */}
      <Container component="main" style={{ textAlign: "center" }}>
        <img src="cLogo.svg" style={{ maxWidth: 100 }} />
      </Container>
      <Box
        component="footer"
        sx={{
          py: 3,
          px: 2,
          mt: "auto",
          backgroundColor: (theme) =>
            theme.palette.mode === "light"
              ? theme.palette.grey[200]
              : theme.palette.grey[800],
        }}
      >
        <Container maxWidth="sm" style={{ textAlign: "center" }}>
          <Copyright />
        </Container>
      </Box>
    </Box>
  );
}
