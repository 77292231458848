import React, { useRef, useCallback } from "react";
import { useGLTF } from "@react-three/drei";
import { useThree } from "@react-three/fiber";
import { useSpring, animated } from "@react-spring/three";
import { Vector3, Box3 } from "three";
import { useMediaQuery, useTheme } from "@material-ui/core";
import useWindowSize from "../../../utils/useWindowSize";

const customConfig = {
  mass: 4.2,
  tension: 1486,
  friction: 40,
  precision: 0.01,
  damping: 50,
};

function worldObjectToScreenCoordinates(obj, camera) {
  var vector = obj.clone(); //new Vector3([dx,dy,dz]);
  var windowWidth = window.innerWidth;
  var widthHalf = windowWidth / 2;
  var heightHalf = window.innerHeight / 2;
  // camera.updateMatrixWorld();
  vector.project(camera);

  vector.x = vector.x * widthHalf + widthHalf;
  vector.y = -(vector.y * heightHalf) + heightHalf;
  vector.z = 0;

  return vector; //.normalize();
}

const screenToWorld = (screenPosition, camera, targetZ = 0) => {
  let vec = new Vector3(); // create once and reuse
  let pos = new Vector3(); // create once and reuse

  vec.set(screenPosition[0], screenPosition[1], 0.5);
  vec.unproject(camera);
  vec.sub(camera.position).normalize();
  let distance = (targetZ - camera.position.z) / vec.z;
  pos.copy(camera.position).add(vec.multiplyScalar(distance));
  return pos;
};

export default function Model({
  letterPosition,
  setInitialAnimFinished,
  initialAnimFinished,
  setLogoBottomPosition = () => {},
}) {
  const { nodes, materials } = useGLTF("/conor.glb");
  const { camera } = useThree();
  const { width, height } = useWindowSize();

  const measuredRef = useCallback(
    (node) => {
      if (node !== null && letterPosition !== false) {
        let pos = screenToWorld(letterPosition, camera);
        node.position.set(pos.x, pos.y + 0.1, pos.z);

        //try to work out where bottom left of logo is on the screen
        var bbox = new Box3().setFromObject(node);
        let bottomOfLogoPosition = worldObjectToScreenCoordinates(
          new Vector3(bbox.min.x, bbox.min.y, 0),
          camera
        );

        setLogoBottomPosition(bottomOfLogoPosition);

        //make group always face camera regardless of viewport width
        // node.lookAt(camera.position);
      }
    },
    [letterPosition, width, height, camera, initialAnimFinished]
  );

  const geometries = ["Text", "Text001", "Text002", "Text003", "Text004"];
  return (
    <group ref={measuredRef} position={[0, 0, 0]}>
      {letterPosition !== false &&
        geometries.map((node, index, ar) => {
          return (
            <LetterMesh
              letterPosition={letterPosition}
              geometry={nodes[geometries[index]].geometry}
              delay={index * 100}
              index={index}
              itemCount={ar.length}
              setInitialAnimFinished={setInitialAnimFinished}
            />
          );
        })}
    </group>
  );
}

useGLTF.preload("/conor.glb");

const LetterMesh = ({
  color = "#985277",
  colorHover = "#ff8c61",
  geometry,
  delay = 0,
  index,
  itemCount,
  letterPosition,
  setInitialAnimFinished,
}) => {
  const groupRef = useRef();
  const theme = useTheme();
  const { width, height } = useWindowSize();

  const xs = useMediaQuery(theme.breakpoints.down("xs"));
  const sm = useMediaQuery(theme.breakpoints.down("sm"));

  const [styles, api] = useSpring(() => ({
    from: {
      color: color,
      xyz: [-10, -5.2, 8],
      xyz2: [1.16, 0.4, 1.3],
    },
    to: [
      {
        xyz: [0, 0, 0],
        xyz2: [2.16, 2.16, 2.16],
        color: color,

        delay: delay,
      },
    ],
    onRest: () => {
      if (index === itemCount - 1) {
        setInitialAnimFinished(true);
      }
    },
  }));

  const measuredRef = useCallback(
    (node) => {
      if (node !== null) {
        const vector = new Vector3();
        node.geometry.computeBoundingBox();
        node.geometry.boundingBox.getCenter(vector);
        node.geometry.center(vector);
      }
    },
    [sm, width, height, letterPosition]
  );

  const mouseEnterHandler = (e) => {
    api.start(() => {
      return {
        to: async (next) => {
          await next({
            color: colorHover,
            config: { duration: 150 },
          });
          await next({
            xyz2: [2.16, 2.16, 1.08],
            config: { ...customConfig },
          });
          await next({
            xyz2: [2.16, 2.16, 2.16],
            config: { ...customConfig },
          });
        },
      };
    });
  };

  const mouseLeaveHandler = (e) => {
    api.start({
      to: {
        color: color,
        config: { duration: 350 },
      },
    });
  };

  return (
    <group
      ref={groupRef}
      position={
          [(xs ? 0.65 : sm ?  0.9 : 1.3) * index, -0.2, 0]
        // sm
        //   ? (sm ? 0.8 : 0.6) * (width / height) * index +
        //     (width > 1000 ? width / 5000 : 0)
        //   : 1.2 * index,
        // sm ? -0.3 : -0.5,
        // 0,
      }
      rotation={[Math.PI / 2, 0, -0.2]}
      // scale={sm ? 0.6 * (width / height) : 1}
      scale={xs ? 0.55 : sm ?  0.8 : 1.1}
    >
      <animated.mesh
        ref={measuredRef}
        key={index + "yeah"}
        position={styles.xyz.to((x, y, z) => {
          return [x * index, y * index, z * index];
        })}
        rotation={styles.xyz.to((x, y, z) => {
          return [0, -index / 100, -z - 0.1];
        })}
        geometry={geometry}
        // material={material}
        scale={styles.xyz2.to((x, y, z) => [x, y, z])}
        onPointerEnter={(e) => {
          mouseEnterHandler(e);
        }}
        onPointerLeave={mouseLeaveHandler}
        onWheel={(e) => {
          if (e.preventDefault !== undefined) {
            e.preventDefault();
          }
        }}
      >
        <animated.meshPhongMaterial color={styles.color.to((x) => x)} />
      </animated.mesh>
    </group>
  );
};
