import React, { useState } from "react";
import PropTypes from "prop-types";
// import classNames from 'classnames';
import { makeStyles, withStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";

const focusedColor = "white"

const useStyles = makeStyles({
  root: {
    // input label when focused
    "& label.Mui-focused": {
      color: focusedColor
    },
    // focused color for input with variant='standard'
    "& .MuiInput-underline:after": {
      borderBottomColor: focusedColor
    },
    // focused color for input with variant='filled'
    "& .MuiFilledInput-underline:after": {
      borderBottomColor: focusedColor
    },
    // focused color for input with variant='outlined'
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: focusedColor
      }
    }
  }
});

const styles = (theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    // width: 200,
  },
  // "& label.Mui-focused": {
  //   color: 'white'
  // },

  cssLabel: {
    color: "white", //theme.palette.secondary.main,
    focused: {color: "white"}
  },

  cssHover: {
    borderColor: theme.palette.primary.main,
  },

  cssOutlinedInput: {
    "&$cssFocused $notchedOutline": {
      borderColor: `${theme.palette.primary.main} !important`,
    },
    "&:hover $notchedOutline": {
      borderColor: `${theme.palette.primary.main} !important`,
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: focusedColor
    },
    color: 'white'
  },

  notchedOutline: {
    borderWidth: "1px",
    borderColor: theme.palette.secondary.main,
  },
});

const ValidField = ({
  classes,
  multiline = false,
  minRows = false,
  label = "",
  name = ""
}) => {
  const [value, setValue] = useState({});

  const handleChange = (label) => (event) => {
    setValue({ [label]: event.target.value });
  };
  // const classes = useStyles()


  return (
    // <form className={classes.container} noValidate autoComplete="off">
      <TextField
        id="standard-name"
        label={label}
        name={name}
        className={classes.root}//textField}
        value={value[label]}
        onChange={(e) => handleChange(label)(e)}
        margin="normal"
        variant="outlined"
        fullWidth
        minRows={minRows}
        multiline={multiline}
        InputLabelProps={{
          classes: {
            root: classes.cssLabel,
            focused: classes.cssFocused,
          },
        }}
        InputProps={{
          classes: {
            root: classes.cssOutlinedInput,
            focused: classes.cssFocused,
            notchedOutline: classes.notchedOutline,
          },
          // style: { color: "white" },
          inputMode: "numeric",
        }}
      />
    // </form>
  );
};

// ValidField.propTypes = {
//   classes: PropTypes.object.isRequired,
// };

export default withStyles(styles)(ValidField);
