import React, { useEffect, useCallback, useState } from "react";
import { useSpring, animated } from "react-spring";
import { useTheme } from "@material-ui/core";
import useWindowSize from "../../../../utils/useWindowSize";

const customConfig = {
  mass: 4.2,
  tension: 1486,
  friction: 40,
  precision: 0.01,
  damping: 5,
};

export default function HeroHeaderLetter({
  heroHeaderLetter = "",
  delay,
  handleLetterPosition = () => {},
  // letterPosition,
}) {
  const [letterColor, setLetterColor] = useState("white");
  const [letterRested, setLetterRested] = useState(false);

  // const normalRef = useRef()
  const { width, height } = useWindowSize();

  const measuredRef = useCallback(
    (node) => {
      if (node && heroHeaderLetter === "C" && letterRested) {
        let letterRect = node.getBoundingClientRect();
        let letterBottomLeftPositionXY = [
          (letterRect.right / window.innerWidth) * 2 - 1,
          -((letterRect.bottom + window.pageYOffset) / window.innerHeight) * 2 +
            1,
        ];
        handleLetterPosition(letterBottomLeftPositionXY);
      }
    },
    [letterRested, width, height]
  );

  const [loadSpring, setLoadSpring] = useSpring(() => {
    return {
      from: {
        xy: [0.4, 1.3],
        opacity: 0,
      },
      to: [
        { opacity: 1, config: { duration: 50 } },
        { xy: [1, 1], config: { ...customConfig } },
      ],
      delay: delay,
      onRest: () => {
        setLetterRested(true);
        //set the pos of letter here?
      },
    };
  });

  const [styles, api] = useSpring(() => ({
    config: {
      mass: 4.2,
      tension: 486,
      friction: 40,
      precision: 0.001,
      damping: 50,
    },
    from: { color: "white", xy: [1, 1] },
  }));

  const theme = useTheme();

  const mouseEnterHandler = (e) => {
    setLetterColor(theme.palette.secondary.main);
    api.start({
      to: [{ xy: [0.4, 1.3] }, { xy: [1, 1], config: { ...customConfig } }],
    });
  };

  const mouseLeaveHandler = (e) => {
    setLetterColor("white");
    // api.start({
    //   to: { color: "white", xy: [1, 1] },
    // });
  };

  return (
    <>
      <animated.div
        ref={measuredRef}
        style={{
          ...styles,
          display: "inline-block",
          fontFamily: theme.typography.fontFamily,
          fontWeight: "bold",
          marginLeft: -3,
          height: heroHeaderLetter === "C" ? 0 : "auto",
          color: "white",
          opacity:
            heroHeaderLetter === "C" ? 0 : loadSpring.opacity.to((x) => x),
          transform: loadSpring.xy.to((x, y) => `scale(${y}, ${x})`),
        }}
        onMouseEnter={() => mouseEnterHandler()}
        onMouseLeave={() => mouseLeaveHandler()}
      >
        <animated.div
          style={{
            color: letterColor, //styles.color.to(x => x),
            transform: styles.xy.to((x, y) => `scale(${y}, ${x})`),
          }}
        >
          {heroHeaderLetter === " " ? <span>&nbsp;</span> : heroHeaderLetter}
        </animated.div>
      </animated.div>
    </>
  );
}
