import React, { useState, useRef } from "react";
import { animated, useSpring } from "react-spring";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core";
import useIntersection from "../../utils/useIntersection";
import SectionSwoosh from "../SectionSwoosh";
import ScrollTrackAnimate from "../../utils/ScrollTrackAnimate";
import HtmlTagger from "../HtmlTagger";

export default function SectionHeader({
  sectionHeaderLines = ["header"],
  fontSizeSmall = "2.5em",
  fontSizeBig = "5em",
  color = "",
  colorHover = "",
}) {
  const sectionHeaderRef = useRef();
  let inViewport = useIntersection(sectionHeaderRef, 0.1);
  return (
    <>
      <div ref={sectionHeaderRef} style={{ height: `${5*sectionHeaderLines.length}vw`, minHeight: 50  }}>
        {inViewport && (
          <HtmlTagger tagText={"h2"}>
            {sectionHeaderLines.map((sectionHeaderLine, index, ar) => {
              return (
                <SectionHeaderLine
                  fontSizeSmall={fontSizeSmall}
                  fontSizeBig={fontSizeBig}
                  sectionHeaderLine={sectionHeaderLine}
                  topDelay={ar.reduce((ac, x, i, ar) => {
                    if (i < index) {
                      ac += x.length * 20;
                    }
                    return ac;
                  }, 0)}
                />
              );
            })}
            {/* <ScrollTrackAnimate> */}
              <SectionSwoosh enteredViewport={inViewport}/>
            {/* </ScrollTrackAnimate> */}
          </HtmlTagger>
        )}
      </div>
    </>
  );
}

const customConfig = {
  mass: 4.2,
  tension: 1486,
  friction: 40,
  precision: 0.01,
  damping: 50,
};

function SectionHeaderLine({
  sectionHeaderLine = "",
  topDelay,
  fontSizeSmall = fontSizeSmall,
  fontSizeBig = fontSizeBig,
}) {
  const theme = useTheme();
  const small = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <div
      style={{
        color: "white",
        fontSize: small ? fontSizeSmall : fontSizeBig,
        lineHeight: 1,
      }}
    >
      {sectionHeaderLine.split("").map((letter, index) => {
        return (
          <SectionHeaderLetter
            sectionHeaderLetter={letter}
            delay={index * 100 + topDelay}
            fontSizeSmall={fontSizeSmall}
            fontSizeBig={fontSizeBig}
          />
        );
      })}
    </div>
  );
}

function SectionHeaderLetter({ sectionHeaderLetter = "", delay }) {
  const [letterColor, setLetterColor] = useState("white");

  const [loadSpring, setLoadSpring] = useSpring(() => {
    return {
      from: {
        xy: [0.4, 1.3],
        opacity: 0,
      },
      to: [
        { opacity: 1, config: { duration: 50 } },
        // { xy: [0.4, 1.3], config: {...customConfig} },
        { xy: [1, 1], config: { ...customConfig } },
      ],
      delay: delay,
    };
  });

  const [styles, api] = useSpring(() => ({
    config: {
      mass: 4.2,
      tension: 486,
      friction: 40,
      precision: 0.001,
      damping: 50,
    },
    from: { color: "white", xy: [1, 1] },
  }));

  const theme = useTheme();

  const mouseEnterHandler = (e) => {
    setLetterColor(theme.palette.secondary.main);
    api.start({
      to: [{ xy: [0.4, 1.3] }, { xy: [1, 1], config: { ...customConfig } }],
    });
  };

  const mouseLeaveHandler = (e) => {
    setLetterColor("white");
    // api.start({
    //   to: { color: "white", xy: [1, 1] },
    // });
  };

  return (
    <>
      <animated.div
        style={{
          ...styles,
          display: "inline-block",
          fontFamily: theme.typography.fontFamily,
          fontWeight: "bold",
          marginLeft: -3,
          color: "white",
          opacity: loadSpring.opacity.to((x) => x),
          transform: loadSpring.xy.to((x, y) => `scale(${y}, ${x})`),
        }}
        onMouseEnter={() => mouseEnterHandler()}
        onMouseLeave={() => mouseLeaveHandler()}
      >
        <animated.div
          style={{
            color: letterColor, //styles.color.to(x => x),
            transform: styles.xy.to((x, y) => `scale(${y}, ${x})`),
          }}
        >
          {sectionHeaderLetter === " " ? (
            <span>&nbsp;</span>
          ) : (
            sectionHeaderLetter
          )}
        </animated.div>
      </animated.div>
    </>
  );
}
