import React from "react";
import { Grid, Typography } from "@material-ui/core";
import ButtonFill from "../../components/ButtonFill";
import SectionHeader from "../../components/sectionHeader/SectionHeader";
import ScrollTrackAnimate from "../../utils/ScrollTrackAnimate";
import PortfolioCard from "./PortfolioCard";
import SectionSwoosh from "../../components/SectionSwoosh";
import HtmlTagger from "../../components/HtmlTagger";

const portfolioProjects = [
  {
    backgroundImagePath: "alsProjectScreenshot.png",
    portfolioPagePath: "projects/als",
  },
  {
    backgroundImagePath: "brazMapPortfolioCardBG.png",
    portfolioPagePath: "/projects/brazelton-map",
  },
  {
    backgroundImagePath: "insightByNumbersScreenshot.png",
    portfolioPagePath: "projects/insight-by-numbers",
  },
];

export default function Portfolio() {
  return (
    <div style={{ minHeight: "100vh", overflow: "hidden" }} id="projects">
      <div style={{ paddingLeft: '5vw', paddingBottom: 0 }}>
        <SectionHeader sectionHeaderLines={["Projects"]} />
      </div>
      <Grid container style={{ minHeight: 400, paddingTop: 50 }}>
        <Grid container>
          <Grid item xs={12} md={6}>
            <div
              style={{
                zIndex: 100,
                position: "relative",
                paddingRight: "3vw",
                paddingLeft: 50,
                paddingBottom: 50,
                paddingTop: 50,
              }}
            >
              <HtmlTagger tagText="p">
                <Typography>Some things I've built recently...</Typography>
              </HtmlTagger>
            </div>
          </Grid>
        </Grid>

        <Grid container style={{ display: "flex", justifyContent: "center" }}>
          {portfolioProjects.map((x) => {
            return (
              <PortfolioCard
                backgroundImagePath={x.backgroundImagePath}
                portfolioPagePath={x.portfolioPagePath}
              />
            );
          })}
        </Grid>
        <Grid
          item
          xs={12}
          style={{
            display: "flex",
            position: "relative",
            justifyContent: "flex-end",
            paddingRight: 20,
            paddingTop: 20,
            paddingBottom: 30,
          }}
        >
          <ButtonFill>See more</ButtonFill>
        </Grid>
      </Grid>
    </div>
  );
}
