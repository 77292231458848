import { Grid, TextareaAutosize, TextField } from "@material-ui/core";
import React, { useRef } from "react";
import SectionHeader from "../../components/sectionHeader/SectionHeader";
import { withStyles } from "@material-ui/styles";
import ButtonFill from "../../components/ButtonFill.js";
import CustomOutlineTextField from "../../components/CustomOutlineTextField";

const StyledTextField = withStyles({
  root: {
    color: "white !important",
    borderColor: "white",
    // backgroundColor: 'red'
  },
})(TextField);

export default function Contact() {
  const formRef = useRef()
  
  const onSubmit = (e) => {
    e.preventDefault()
    // console.log(e.currentTarget)
    let form = new FormData(formRef.current)

    for(let entry of form.entries()){
      console.log(entry)
    }
  };

  return (
    <div style={{ paddingLeft: 50, minHeight: "100vh" }}>
      <SectionHeader sectionHeaderLines={["Contact"]} />
      <div style={{ paddingTop: 50 }}>
        <Grid container>
          <Grid item sm={12} md={6}>
            <Grid container>
              <form name="contact-form" style={{ width: "100%" }} ref={formRef}>
                <Grid sm={12} md={6} style={{ maxWidth: "100%", padding: 10 }}>
                  <CustomOutlineTextField label="Name" name="name" />
                </Grid>
                <Grid
                  item
                  sm={12}
                  md={6}
                  style={{ maxWidth: "100%", padding: 10 }}
                >
                  <CustomOutlineTextField label="Email" name="email"/>
                </Grid>
                <Grid item xs={12} style={{ padding: 10 }}>
                  <CustomOutlineTextField label="Subject" name="subject" />
                </Grid>
                <Grid item xs={12} style={{ padding: 10 }}>
                  <CustomOutlineTextField
                    label="Message"
                    multiline
                    minRows="20"
                    name="message"
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  style={{
                    padding: 10,
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <ButtonFill type="submit" onClick={onSubmit}>Send</ButtonFill>
                </Grid>
              </form>
            </Grid>
          </Grid>

          <Grid
            item
            sm={12}
            md={6}
            style={{
              // display: 'block',
              minHeight: 800,
              width: "100%",
              height: "100%",
              backgroundImage: `url("contactMapPicture2.webp")`,
              backgroundSize: "cover",
              backgroundPosition: "center center",
            }}
          ></Grid>
        </Grid>

        <Grid container></Grid>
      </div>
    </div>
  );
}
