import React from "react";
import { Typography } from "@material-ui/core";

export default function NavDrawerHeader() {
  return (
    <div style={{ backgroundColor: "black" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          paddingTop: 15,
          width: "100%",
        }}
      >
        <img
          src={`${process.env.PUBLIC_URL}/cLogo.svg`}
          style={{ paddingTop: 20, width: 50, display: "block" }}
        />
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          paddingTop: 5,
        }}
      >
        <Typography style={{ fontSize: "1.5em", fontWeight: "bold" }}>
          Conor
        </Typography>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          paddingTop: 12,
          paddingBottom: 10,
        }}
      >
        <Typography style={{ fontSize: "0.8em" }}>Web Developer</Typography>
      </div>
    </div>
  );
}
