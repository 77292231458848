import { Grid, Typography } from "@material-ui/core";
import React from "react";
import SectionHeader from "../../../components/sectionHeader/SectionHeader";
import SectionSwoosh from "../../../components/SectionSwoosh";
import ScrollTrackAnimate from "../../../utils/ScrollTrackAnimate";
import BlogArticleListCard from "../BlogArticleListCard";

const blogArticles = [
  {
    lastUpdated: "Fri, 17 Sep 2021 08:02:24 GMT",
    createdAt: "Fri, 17 Sep 2021 08:02:24 GMT",
    author: "Conor Breslin",
    title: "My First Blog Article",
    subtitle: "",
    article:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
  },
  {
    lastUpdated: "Fri, 17 Sep 2021 08:02:24 GMT",
    createdAt: "Fri, 17 Sep 2021 08:02:24 GMT",
    author: "Conor Breslin",
    title: "My Second Blog Article",
    subtitle: "",
    article:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
  },
  {
    lastUpdated: "Fri, 17 Sep 2021 08:02:24 GMT",
    createdAt: "Fri, 17 Sep 2021 08:02:24 GMT",
    author: "Conor Breslin",
    title: "My Second Blog Article",
    subtitle: "",
    article: "Lorem Ipsum is simply dummy text.",
  },
  {
    lastUpdated: "Fri, 17 Sep 2021 08:02:24 GMT",
    createdAt: "Fri, 17 Sep 2021 08:02:24 GMT",
    author: "Conor Breslin",
    title: "My Second Blog Article",
    subtitle: "",
    article:
      "Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
  },
];

export default function BlogHighlights() {
  return (
    <div style={{ paddingBottom: 100 }}>
      
      <div style={{ paddingLeft: 50, paddingBottom: 0 }}>
        <SectionHeader sectionHeaderLines={["Blog"]} />
      </div>
      {/* <ScrollTrackAnimate>
        <SectionSwoosh />
      </ScrollTrackAnimate> */}
      <Grid
        container
        style={{
          marginTop: -100,
          display: "block",
        }}
      >
        <div
          style={{
            height: 0,
            marginLeft: "20vw",
            bottom: "-12vw",
            paddingRight: "3vw",
          }}
        >
          <Typography
            style={{ fontSize: "22.5vw", color: "#2a2a2a", fontWeight: "bold" }}
          >
            Blog
          </Typography>
        </div>
        <div style={{ paddingLeft: 50, paddingTop: 100 }}>
          <Grid container>
            {blogArticles.map((blogArticle) => {
              return (
                <Grid item xs={12} md={6}>
                  <ScrollTrackAnimate>
                    <BlogArticleListCard blogArticle={blogArticle} />
                  </ScrollTrackAnimate>
                </Grid>
              );
            })}
          </Grid>
        </div>
      </Grid>
    </div>
  );
}
