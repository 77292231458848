import { Grid, useTheme } from "@material-ui/core";
import { SettingsInputAntenna } from "@material-ui/icons";
import React, { useRef, useState, useEffect } from "react";
import ParallaxWrap from "../../components/ParallaxWrap";
import useIntersection from "../../utils/useIntersection";
import { HeroHeader } from "./heroHeader/HeroHeader";
import HeroSubHeaderAndCTA from "./heroHeader/HeroSubHeaderAndCTA";
import HeroLogoAnimation from "./HeroLogoAnimation";
import HeroLogo from "./heroLogo/HeroLogo";

const HeroParallaxContainer = ({ children }) => {
  return (
    <div
      style={{
        height: "100%",
        overflow: "hidden",
        width: "100%",
        position: "relative",
      }}
      id="about"
    >
      {children}
    </div>
  );
};

const Hero = () => {
  const theme = useTheme();
  const ref = useRef();

  // const inViewport = useIntersection(ref, 0.01);

  const [letterPosition, setLetterPosition] = useState(false);
  const [logoBottomPosition, setLogoBottomPosition] = useState(false);

  useEffect(() => {
    console.log(
      "logoBottomPosition inside useEffect in Hero component",
      logoBottomPosition
    );
  }, [logoBottomPosition]);

  const handleLetterPosition = (pos) => {
    console.log("letterposition", pos);
    setLetterPosition(pos);
  };

  const [initialAnimFinished, setInitialAnimFinished] = useState(false);

  return (
    <>
      <div style={{ height: "100vh", minHeight: 800, position: "relative" }}>
        <div
          // ref={ref}
          style={{
            width: "100%",
            // paddingTop: '10vh',
            height: "calc(120vh + 64px)",
            // minHeight: 800,
            position: "absolute",
          }}
        >
          <HeroParallaxContainer>
            <Grid container>
              <Grid item xs={12} style={{ marginTop: -64 }}>
                <HeroLogo
                  letterPosition={letterPosition}
                  setInitialAnimFinished={setInitialAnimFinished}
                  initialAnimFinished={initialAnimFinished}
                  setLogoBottomPosition={setLogoBottomPosition}
                />
              </Grid>
              <Grid
                item
                xs={12}
                style={{ paddingTop: "0vh", position: "absolute" }}
              >
                <HeroHeader
                  handleLetterPosition={handleLetterPosition}
                  letterPosition={letterPosition}
                />
              </Grid>
              {/* <HeroLogoAnimation /> */}
            </Grid>
          </HeroParallaxContainer>
        </div>
        <Grid
          item
          xs={12}
          style={{
            position: "absolute",
            marginTop: logoBottomPosition.y,
          }}
        >
          {initialAnimFinished && logoBottomPosition && (
            <>
              <HeroHeader heroHeaderLines={["Web Developer"]} />
              <div
                style={{
                  paddingLeft: "5vw",
                  position: "absolute",
                }}
              >
                <HeroSubHeaderAndCTA />
              </div>
            </>
          )}
        </Grid>
      </div>
    </>
  );
};

export { Hero };
