import React from "react";
import HeroHeaderLine from "./heroHeaderLine/HeroHeaderLine";
// import HeroHeaderBlobBGAnimation from "./heroHeaderBlobBGAnimation/HeroHeaderBlobBGAnimation";
import ButtonFill from "../../../components/ButtonFill";
import { Typography } from "@material-ui/core";
import HtmlTagger from "../../../components/HtmlTagger";
import useWindowSize from "../../../utils/useWindowSize";

const HeroHeader = ({
  handleLetterPosition = () => {},
  letterPosition,
  heroHeaderLines = ["Hi, I'm ", "C"],
}) => {
  // const heroHeaderLines = ["Hi,", "I'm C", "web developer"];

  //track where the C in conor is on the screen and pass this up to the parent container to pass to the canvas so the 3d 'CONOR' can be positioned
  //correctly

  return (
    <div
      style={{
        paddingLeft: "5vw",
        paddingTop: '10vh',
        // background: 'green'
        // position: "absolute",
        // boxShadow: `rgba(255,117,3, 0.4) 5px 5px, rgba(255,117,3, 0.3) 10px 10px, rgba(255,117,3, 0.2) 15px 15px, rgba(255,117,3, 0.1) 20px 20px, rgba(255,117,3, 0.05) 25px 25px`,
      }}
    >
      {/* <HtmlTagger tagText="h1"> */}
      {heroHeaderLines.map((heroHeaderLine, index, ar) => {
        return (
          <HeroHeaderLine
            handleLetterPosition={handleLetterPosition}
            letterPosition={letterPosition}
            heroHeaderLine={heroHeaderLine}
            topDelay={ar.reduce((ac, x, i, ar) => {
              if (i < index) {
                ac += x.length * 20;
              }
              return ac;
            }, 0)}
          />
        );
      })}
      {/* </HtmlTagger> */}
    </div>
  );
};

export { HeroHeader };
