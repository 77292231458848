import React, { useEffect, useState } from "react";
import HeroHeaderLetter from "../heroHeaderLetter/HeroHeaderLetter";
import { animated, useTrail } from "react-spring";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core";

const customConfig = {
  mass: 4.2,
  tension: 1486,
  friction: 40,
  precision: 0.01,
  damping: 50,
};

export default function HeroHeaderLine({
  heroHeaderLine = "",
  topDelay,
  handleLetterPosition = () => {},
  letterPosition,
}) {
  const theme = useTheme();
  const small = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <div
      style={{
        color: "white",
        fontSize: small ? "2.5em" : "5em",
        // lineHeight: "100px",
      }}
    >
      {heroHeaderLine.split("").map((letter, index) => {
        return (
          <HeroHeaderLetter
            heroHeaderLetter={letter}
            delay={index * 100 + topDelay}
            handleLetterPosition={handleLetterPosition}
            letterPosition={letterPosition}
          />
        );
      })}
    </div>
  );
}
