import { Typography } from "@material-ui/core";
import React from "react";
import { animated, useSpring } from "react-spring";
import ButtonFill from "../../../components/ButtonFill";

export default function HeroSubHeaderAndCTA() {
  const [styles, api] = useSpring(() => ({
    from: {
      opacity: 0,
    },
    to: [
      {
        opacity: 1,
        delay: 1500,
        duration: 500,
      },
    ],
  }));
  return (
    <>
      <animated.div style={{ opacity: styles.opacity.to((x) => x) }}>
        <Typography style={{ fontSize: "1rem", paddingTop: 10 }}>
          Full Stack JS Developer / Data Analyst <br />
          based in Cambridge, UK
        </Typography>
        <div
          style={{ paddingTop: 30 }}
          onMouseEnter={(e) =>
            console.log("mouse entered cta button parent div")
          }
        >
          <ButtonFill>Get in Touch</ButtonFill>
        </div>
      </animated.div>
    </>
  );
}
