import React from "react";
import { useTheme } from "@material-ui/core";

export default function HtmlTagger({ children, tagText }) {
  const theme = useTheme();
  return (
    <>
      <div
        style={{
          paddingBottom: 10,
          marginLeft: -15,
          color: theme.palette.primary.main,
          fontSize: "0.75em",
        }}
      >
        <i>{"<" + tagText + ">"}</i>
      </div>
      {children}
      <div
        style={{
          // padding: 10,
          marginLeft: -15,
          color: theme.palette.primary.main,
          fontSize: "0.75em",
        }}
      >
        <i>{"</" + tagText + ">"}</i>
      </div>
    </>
  );
}
