import React from "react";
import { useSpring, animated } from "react-spring";
import { useTheme } from "@material-ui/core/styles";

const ButtonFill = ({
  buttonText,
  active,
  fontSizing,
  children,
  disabled = false,
  fixed = false,
  onClick = () => {}
}) => {
  const theme = useTheme();


  const [spring, setSpring] = useSpring(() => ({
    background: 0,
    color: theme.palette.secondary.light,
    borderOutlineColor: theme.palette.secondary.light,
    config: { mass: 1, tension: 1000, friction: 100 }
  }));

  const borderOutlineColor = x => {
    return `1px solid ${x}`;
  };

  const backgroundGrad = x => {
    return `linear-gradient(90deg, ${theme.palette.secondary.light} ${x}%, ${theme.palette.secondary.light} ${x}%, transparent ${x}%, transparent`;
  };

  return (
    <div style={{ display: "inline-block" }} onClick={onClick}>
      {!disabled && !fixed && (
        <animated.div
          onMouseEnter={() => {
            setSpring({
              background: 100,
              color: "white",
              borderOutlineColor: theme.palette.primary.light
            });
          }}
          onMouseLeave={() => {
            setSpring({
              background: 0,
              color: theme.palette.secondary.light,
              borderOutlineColor: theme.palette.secondary.light
            });
          }}
          style={{
            cursor: "pointer",
            background: active
              ? theme.palette.secondary.light
              : spring.background.to(backgroundGrad),
            padding: 10,
            paddingLeft: 40,
            paddingRight: 40,
            color: active ? "white" : spring.color,
            // fontWeight: "bold",
            whiteSpace: "nowrap",
            fontSize: `${fontSizing}rem`,
            fontFamily: theme.typography.fontFamily,
            border: spring.borderOutlineColor.to(borderOutlineColor), //`1px solid white`,//`1px solid ${theme.palette.secondary.light}`,
            borderRadius: 3
          }}
        >
          {children}
          {buttonText}
        </animated.div>
      )}
      {disabled && !fixed && (
        <div
          style={{
            cursor: "cursor",
            background: 'rgba(233, 233, 233, 1)',
            padding: 10,
            paddingLeft: 40,
            paddingRight: 40,
            color: 'rgba(203, 203, 203, 1)',
            // fontWeight: "bold",
            whiteSpace: "nowrap",
            fontSize: `${fontSizing}rem`,
            border: `1px solid rgba(203, 203, 203, 1)`,//spring.borderOutlineColor.interpolate(borderOutlineColor), //`1px solid white`,//`1px solid ${theme.palette.secondary.light}`,
            // borderRadius: 30
          }}
        >{children}
        {buttonText}</div>
      )}
      {fixed && (
        <div
        style={{
          cursor: "cursor",
          background: theme.palette.primary.light,
          padding: 10,
          paddingLeft: 40,
          paddingRight: 40,
          color: theme.palette.secondary.light,
          // fontWeight: "bold",
          whiteSpace: "nowrap",
          fontSize: `${fontSizing}rem`,
          border: theme.palette.secondary.light, //`1px solid white`,//`1px solid ${theme.palette.secondary.light}`,
          borderRadius: 30
        }}
      >{children}
      {buttonText}</div>
      )}
    </div>
  );
};

export default ButtonFill;
