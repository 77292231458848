import { Grid, useTheme } from "@material-ui/core";
import { useSpring } from "@react-spring/core";
import { animated } from "@react-spring/web";
import React, { useEffect } from "react";
import HtmlTagger from "../../components/HtmlTagger";
import theme from "../../utils/theme";

//blogApiShape

const BlogArticleListCardTitle = ({ blogArticleTitle }) => {
  const theme = useTheme();
  return (
    <div
      style={{
        color: theme.palette.secondary.dark,
        fontSize: "2em",
        fontWeight: "bold",
      }}
    >
      {blogArticleTitle}
    </div>
  );
};

const BlogArticleListCardDate = ({ blogArticleListCardDate }) => {
  return (
    <div>{new Date(blogArticleListCardDate).toUTCString().toUpperCase()}</div>
  );
};

const BlogArticleListCardTruncatedIntro = ({
  blogArticleListCardTruncatedIntro,
}) => {
  return (
    <div style={{ paddingTop: 20 }}>
      {blogArticleListCardTruncatedIntro
        .split("")
        .filter((y, i) => i < 250)
        .join("")}
      ...
    </div>
  );
};

export default function BlogArticleListCard({ blogArticle, enteredViewport }) {
  const theme = useTheme();
  const [spring, setSpring] = useSpring(() => ({
    x: -500,
    y: 0
  }));
  useEffect(() => {
    setSpring({ x: enteredViewport ? 0 : -500, y: enteredViewport ? 1 : 0 });
  }, [enteredViewport]);
  
  return (
    <animated.div style={{ paddingRight: 30, paddingTop: 40, position: 'relative', left: spring.x.to(x => x), opacity: spring.y.to(y => y) }}>
      <HtmlTagger tagText="article">
        <BlogArticleListCardTitle blogArticleTitle={blogArticle.title} />
        <BlogArticleListCardDate
          blogArticleListCardDate={blogArticle.createdAt}
        />
        <BlogArticleListCardTruncatedIntro
          blogArticleListCardTruncatedIntro={blogArticle.article}
        />
        <div
          style={{
            color: theme.palette.secondary.light,
            paddingBottom: 15,
            paddingTop: 10,
          }}
        >
          read more...
        </div>
      </HtmlTagger>
    </animated.div>
  );
}
