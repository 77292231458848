import React, { Suspense, useEffect } from "react";
import { Canvas } from "@react-three/fiber";
import { PerspectiveCamera } from "@react-three/drei";
import Model from "./Model";
import { Globals } from "@react-spring/shared";
import useWindowSize from "../../../utils/useWindowSize";

Globals.assign({
  frameLoop: "always",
});

export default function HeroLogo({
  letterPosition,
  initialAnimFinished,
  setInitialAnimFinished = () => {},
  setLogoBottomPosition = () => {},
}) {
  const windowSize = useWindowSize();
  useEffect(() => {
    console.log("windowSize", windowSize);
  }, [windowSize]);
  return (
    <Canvas
      shadows
      // dpr={[2, 2]}
      style={{
        position: "absolute",
        height: "100%",
        // minHeight: 800,
        width: "100vw",
        // pointerEvents: "none"
      }}
    >
      <PerspectiveCamera
        position={[0, 0, 10]}
        rotation={[0, 0, 0]}
        makeDefault
      />
      <ambientLight intensity={0.45} />
      <pointLight position={[5, 0, 25]} intensity={0.4} />
      <Suspense fallback={null}>
        <Model
          letterPosition={letterPosition}
          initialAnimFinished={initialAnimFinished}
          setInitialAnimFinished={setInitialAnimFinished}
          setLogoBottomPosition={setLogoBottomPosition}
        />
      </Suspense>
    </Canvas>
  );
}
