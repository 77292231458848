import { useTheme, Button } from "@material-ui/core";
import React, { useEffect } from "react";
import { HashLink as Link } from "react-router-hash-link";
//need to link to a hash for page location?
//- maybe section header should be passed
//a hash to use as an id to facilitate scroll to functionality here
import { animated, useSpring } from "react-spring";
import { HeroHeader } from "../hero/heroHeader/HeroHeader";

const linkBackgroundIntepolator =
  ({ linkIdleColor = "", linkHoverColorStart = "", linkHoverColorEnd = "" }) =>
  (interpolateValue = 0) => {
    return `radial-gradient(circle at 50% 50%, ${linkHoverColorStart} ${0}%, ${linkHoverColorEnd} ${
      interpolateValue * 50
    }%, ${linkIdleColor} ${interpolateValue * 50}%`;
    // `radial-gradient(circle, ${linkHoverColorStart} ${
    //   interpolateValue * 12
    // }px,${linkHoverColorEnd} ${interpolateValue * 24}px)`;
  };

export default function NavDrawerLink({ to = "", linkText = "" }) {
  const theme = useTheme();
  const [styles, api] = useSpring(() => ({
    from: { x: 0, color: "white" },
    // to: { x: 1 },
  }));

  const onMouseEnter = () => {
    api.start({ to: { x: 1, color: "#1d1d1d" }, config: { friction: 50 } });
  };

  const onMouseLeave = () => {
    api.start({ to: { x: 0, color: "white" }, config: { friction: 30 } });
  };

  const linkBackground = linkBackgroundIntepolator({
    linkIdleColor: "transparent",
    linkHoverColorStart: "#1d1d1d",//theme.palette.primary.dark, //theme.palette.secondary.main,
    linkHoverColorEnd: "#333333"//theme.palette.primary.main,
  });

  return (
    <Link
      smooth
      to={{ pathname: "/", hash: "#projects" }}
      style={{
        textDecoration: "none",
        color: "inherit",
        // textDecoration: "bold",
      }}
    >
        {/* , borderBottom: "1px solid white"  */}
      <div style={{ paddingTop: 0}}>
        <animated.div
          style={{
            cursor: "pointer",
            padding: 40,
            // color: styles.color.to((x) => x),
            backgroundSize: "10px 10px",
            //   backgroundRepeat: "repeat",
            fontWeight: styles.x.to((x) => (x > 0.5 ? "bold" : "normal")),
            fontSize: styles.x.to((x) => `${x + 1}em`),
            backgroundImage: styles.x.to(linkBackground),
          }}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
        >
          {linkText}

          {/* <HeroHeader heroHeaderLines={[linkText]} /> */}
          {/* {linkText} */}
          {/* <Button>{linkText}</Button> */}
        </animated.div>
      </div>
    </Link>
  );
}
