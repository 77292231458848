import React, { useRef, useState } from "react";
import { useSpring, animated } from "react-spring";
import { useScrollPosition } from "@n8tb1t/use-scroll-position";

export default function ScrollTrackAnimate({ children, config = {} }) {
  const [spring, setSpring] = useSpring(() => ({ x: 0 }));
  const [enteredViewport, setEnteredViewport] = useState(false);
  const [exitedViewport, setExitedViewport] = useState(false);
  const ref = useRef(null);

  useScrollPosition(({ prevPos, currPos }) => {
    if (
      ref.current.getBoundingClientRect().top - window.innerHeight < -100 &&
      ref.current.getBoundingClientRect().bottom > 100 &&
      currPos.y < prevPos.y
    ) {
      setSpring({ x: 1, delay: 100 });
      setEnteredViewport(true);
      setExitedViewport(false);
    } else {
      // setSpring({ x: 0 });
      // setEnteredViewport(false);
      // setExitedViewport(true);
    }
  });

  return (
    <animated.span
      ref={ref}
      style={Object.keys(config).reduce((ac, x) => {
        ac[x] = spring.x.to(config[x]);
        return ac;
      }, {})}
    >
      {React.cloneElement(children, { enteredViewport, exitedViewport })}
      {/* {React.cloneElement(children[0], {enteredViewport, exitedViewport})} */}
    </animated.span>
  );
}
