import React, { useState } from "react";
import { Grid } from "@material-ui/core";
import { useSpring } from "@react-spring/core";
import { animated } from "@react-spring/web";
import ButtonFill from "../../components/ButtonFill";
import { Link } from "react-router-dom";

export default function PortfolioCard({
  backgroundImagePath = "brazMapPortfolioCardBG.png",
  portfolioPagePath = "/projects/brazelton-map",
}) {
  const [mouse, setMouse] = useState(false);

  const [spring, setSpring] = useSpring((x) => ({
    from: { x: 0, bg: "#00000000" },
  }));

  const handleMouse = (MouseIn) => {
    setMouse(MouseIn);
    setSpring({ x: MouseIn ? 1 : 0, bg: "#1d1d1d00" });
  };

  return (
    <Grid
      item
      xs={12}
      sm={6}
      md={4}
      style={{
        boxSizing: "border-box",
        // border: "1px solid white",
        minHeight: 300,
        zIndex: 10,

        backgroundImage: `url('${backgroundImagePath}')`,
        backgroundPosition: "center center",
        backgroundSize: "cover",
      }}
      onMouseEnter={() => handleMouse(true)}
      onMouseLeave={() => handleMouse(false)}
    >
      {mouse && (
        <animated.div
          style={{
            // backgroundColor: spring.bg,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
          }}
        >
          <animated.span
            style={{ opacity: spring.x, backgroundColor: "#1d1d1d" }}
          >
            <Link to={portfolioPagePath}>
              <ButtonFill>View Project</ButtonFill>
            </Link>
          </animated.span>
        </animated.div>
      )}
    </Grid>
  );
}
