import React from "react";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import IconButton from "@material-ui/core/IconButton";
import MenuOpenIcon from "@material-ui/icons/MenuOpen";
import { Typography, useTheme } from "@material-ui/core";
import NavDrawerHeader from "./NavDrawerHeader";
import NavDrawerLink from "./NavDrawerLink";

export default function SwipeableTemporaryDrawer() {
  const [state, setState] = React.useState({
    open: false,
  });

  const theme = useTheme();

  const toggleDrawer = (open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, open: open });
  };

  const list = () => (
    <div
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
      style={{
        width: 300,
        background: "#1d1d1d",//theme.palette.primary.main,
        minHeight: "100%",
        color: "white",
        backgroundColor: "#181818",
      }}
    >
      <NavDrawerHeader />
     {["Projects", "About", "Contact"].map(x => {
       return(<NavDrawerLink to="/" linkText={x} />)
     })} 
    </div>
  );

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "flex-end",
        paddingRight: 10,
        paddingTop: 10,
      }}
    >
      {/* {['right'].map(() => ( */}
      <React.Fragment>
        <IconButton
          style={{ backgroundColor: theme.palette.secondary.main }}
          color="secondary"
          onClick={toggleDrawer(true)}
          // style={{ backgroundColor: "white" }}
        >
          <MenuOpenIcon color="primary" />
        </IconButton>
        <SwipeableDrawer
          anchor={"right"}
          open={state["open"]}
          onClose={toggleDrawer(false)}
          onOpen={toggleDrawer(true)}
        >
          {list()}
        </SwipeableDrawer>
      </React.Fragment>
      {/* ))} */}
    </div>
  );
}
