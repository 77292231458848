import React from "react";
import { Hero } from "./features/hero/Hero.js";
import AppBar from "./features/appBar/AppBar.js";
import { ThemeProvider } from "@material-ui/core";
import theme from "./utils/theme.js";
import Contact from "./features/contact/Contact";
import About from "./features/about/About";
import Portfolio from "./features/portolio/Portfolio";
import { Route, Routes, BrowserRouter as Router, useParams } from "react-router-dom";
import PortfolioPage from "./features/portolio/PortfolioPage.js";
import BlogHighlights from "./features/blog/blogHighlights/BlogHighlights.js";
import Footer from "./features/footer/Footer.js";


const HomePage = () => {
  return (
    <>
      <Hero />
      <Portfolio />
      <About />
      <BlogHighlights />
      <Contact />
    </>
  );
};

function App() {
  return (
    <ThemeProvider theme={theme}>
      <div className="App" style={{ background: "#1d1d1d", color: "white" }}>
        
        <Router>
        <AppBar />
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/projects/:name" children={<Route element={<PortfolioPage />} />}/>
          {/* {(params) => {
            return <PorfolioPage name={params.name} />; 
          }} */}
        {/* </Route> */}
          </Routes>
        </Router>
        <Footer />
      </div>
    </ThemeProvider>
  );
}

export default App;
