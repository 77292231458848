import { useTheme } from "@material-ui/core";
import React, { useEffect, useRef } from "react";
import SectionHeader from "../../../components/sectionHeader/SectionHeader";
import { Chip } from "@material-ui/core";
import { animated, useSpring } from "react-spring";
import useIntersection from "../../../utils/useIntersection";

const technologies = [
  {
    type: "technology",
    name: "Docker",
    iconPath: process.env.PUBLIC_URL + "dockerLogo.svg",
  },
  {
    type: "tool",
    name: "Material UI",
    iconPath: process.env.PUBLIC_URL + "muiLogo.svg",
  },
  {
    type: "technology",
    name: "NodeJS",
    iconPath: process.env.PUBLIC_URL + "nodeLogo.svg",
  },
  {
    type: "technology",
    name: "Git",
    iconPath: process.env.PUBLIC_URL + "gitLogo.svg",
  },
  {
    type: "technology",
    name: "GitHub",
    iconPath: process.env.PUBLIC_URL + "githubLogo.svg",
  },
  {
    type: "tool",
    name: "Express",
    iconPath: process.env.PUBLIC_URL + "expressLogo.svg",
  },
  {
    type: "technology",
    name: "MongoDB",
    iconPath: process.env.PUBLIC_URL + "mongoDBLogo.svg",
  },
  {
    type: "tool",
    name: "Jest",
    iconPath: process.env.PUBLIC_URL + "jestLogo.svg",
  },
  {
    type: "technology",
    name: "React",
    iconPath: process.env.PUBLIC_URL + "reactLogo.svg",
  },
  //   { type: "technology", name: "ES6", iconPath: process.env.PUBLIC_URL + "" },
  {
    type: "technology",
    name: "Linux",
    iconPath: process.env.PUBLIC_URL + "linuxLogo.svg",
  },
  //   { type: "tool", name: "React-Spring", iconPath: process.env.PUBLIC_URL + "" },
];

const TechnologyThumbnail = ({ name, iconPath, delay, xOffsetPercent }) => {
  console.log(delay);
  const [spring, set] = useSpring(() => ({
    from: { x: 0, y: xOffsetPercent },
    // to: { x: 1 },
  }));

  useEffect(() => {
    set({ to: { x: 1, y: '0vh' }, delay: delay });
    console.log(xOffsetPercent)
  }, []);

  return (
    <animated.div
      style={{
        display: "inline-block",
        opacity: spring.x.to((x) => x),
        position: "relative",

        // top: xOffsetPercent,
      }}
    >
      <animated.div style={{ position: "relative", right: spring.y.to(y => y) }}>
        <div style={{ display: "inline-block", padding: 10 }}>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <div style={{ padding: 10 }}>
              <img
                src={iconPath}
                style={{
                  width: "4vw",
                  height: "4vw",
                  minWidth: 50,
                  minHeight: 50,
                }}
              />
            </div>
          </div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <div style={{ display: "block" }}>
              <Chip label={name} color="primary" variant="outlined" />
            </div>
          </div>
        </div>
      </animated.div>
    </animated.div>
  );
};

// const TechnologyThumbnailAnimationWrapper = ({ children, delay }) => {
//   const [spring, set] = useSpring({
//     from: { x: 0 },
//     to: { x: 1 },
//     config: { delay: delay },
//   });

//   return (
//     <animated.div
//       style={{ display: "inline-block", opacity: spring.x.to((x) => x) }}
//     >
//       {children}
//     </animated.div>
//   );
// };

export default function SkillsAndTechnologies() {
  const theme = useTheme();
  const ref = useRef();
  const inViewport = useIntersection(ref, 0.2);
  return (
    <div style={{ position: "relative", paddingTop: 50, paddingLeft: 50 }}>
      <div>
        <SectionHeader
          sectionHeaderLines={["Skills ", "&", "Technologies"]}
          color={theme.palette.secondary.light}
          colorHover={theme.palette.primary.main}
          fontSizeBig="3em"
          fontSizeSmall="1.5em"
        />
      </div>
      <div ref={ref} style={{ height: "100%" }}>
        {inViewport &&
          technologies.map((tech, i, ar) => {
            return (
              // <TechnologyThumbnailAnimationWrapper delay={i * 100}>
              <TechnologyThumbnail
                iconPath={tech.iconPath}
                name={tech.name}
                delay={i * 100}
                xOffsetPercent={`${-((i + 1) / ar.length) * 100}vh`}
                key={i}
              />
              // </TechnologyThumbnailAnimationWrapper>
            );
          })}
      </div>
    </div>
  );
}
