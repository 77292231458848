import React from "react";
import SectionHeader from "../../components/sectionHeader/SectionHeader";
import ScrollTrackAnimate from "../../utils/ScrollTrackAnimate";
import SectionSwoosh from "../../components/SectionSwoosh";
import { Typography } from "@material-ui/core";
import ButtonFill from "../../components/ButtonFill";
import { Grid } from "@material-ui/core";
import HtmlTagger from "../../components/HtmlTagger";
import SkillsAndTechnologies from "../portolio/SkillsAndTechnologies/SkillsAndTechnologies";

export default function About() {
  return (
    <div style={{ minHeight: "100vh", overflow: 'hidden' }}>
      <div style={{ paddingLeft: 50}}>
        <SectionHeader sectionHeaderLines={["About"]} />
      </div>
      {/* <ScrollTrackAnimate>
        <SectionSwoosh />
      </ScrollTrackAnimate> */}
      <div
        style={{
          height: 0,
          width: "100%",
          display: "flex",
          marginLeft: "20vw",
          justifyContent: "flex-start",
          paddingRight: "3vw",
          zIndex: 0,
        }}
      >
        <Typography
          style={{ fontSize: "22.5vw", color: "#2a2a2a", fontWeight: "bold" }}
        >
          About
        </Typography>
      </div>
      <Grid container>
        <Grid item xs={12} md={4}>
          <div
            style={{
              zIndex: 100,
              position: "relative",
              paddingTop: 100,
              paddingRight: "3vw",
              paddingLeft: 50,
            }}
          >
            <HtmlTagger tagText="p">
              <Typography>
                I am a freelance data analyst and self-taught Javascript
                developer. Open to projects or the right permanent role. I am based in
                Cambridge, UK. If you want to discuss a potential project or are
                interested in hiring me, please
              </Typography>
            </HtmlTagger>
            <div style={{ paddingTop: 20 }}>
              <ButtonFill>Drop me a line</ButtonFill>
            </div>
          </div>
        </Grid>
        <Grid item xs={12} md={6}>
          <SkillsAndTechnologies />
        </Grid>
      </Grid>
    </div>
  );
}
