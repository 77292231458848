import { createTheme } from '@material-ui/core/styles';

const theme = createTheme({
    palette: {
      primary: {
        main: '#ff8c61',
      },
      secondary: {
        main: '#985277',
      },
    },
    typography: {
        fontFamily: 'Azeret Mono'
    }
  });
  
  export default theme